<template>
  <div class="row account-setup">
    <div class="col">
      <get-code
        v-if="step == 1"
        :default-channel="channel"
        :default-value="value"
        @submit="onGetCode"
      />

      <validate-code
        v-if="step == 2"
        :channel="channel"
        :value="value"
        @resend="onResend"
        @submit="onValidateCode"
      />

      <set-credentials
        v-show="step == 3"
        :access_code="access_code"
        :account="selectedAccount"
        :value="value"
        :channel="channel"
        :accounts="accounts"
        :uuid="uuid"
        @setUser="setAccountToActivate"
        @cancel="onCancel"
        @submit="onSubmitCredentials"
      />
    </div>
  </div>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import GetCode from './GetCode'
import ValidateCode from './ValidateCode'
import SetCredentials from './SetCredentials'
import { Helpers } from '@/mixins/Helpers'
import { loggedUserIsRespondent } from '@/mixins/UserManagement/UserRoles'
import { mapGetters } from 'vuex'
import { initializeAnonymousAppUser } from '@/mixins/ThirdPartyApps'

export default {
  name: 'AccountSetup',
  components: {
    GetCode, ValidateCode, SetCredentials
  },
  mixins: [ErrorsDictionary, Helpers],
  data () {
    return {
      step: this.$route.query.step || 1,
      channel: this.$route.query.channel || '',
      value: '',
      selectedAccount: null,
      access_code: '',
      uuid: ''
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'accounts',
      gSettings: 'generalSettings',
      accessCodeUUID: 'getAccessCodeUUID'
    })
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
    // OWL-7687 if skipping manual access code entry and coming in from a link, set the user to jump to step 3
    if (this.$route.query.step) {
      const account = this.accounts.find(account => account.uuid === this.accessCodeUUID)
      this.setAccountToActivate(account)
    }
  },
  created () {
    if (this.gSettings.customer_name.toLowerCase().includes('polara')) {
      this.anonymousUserLoaded = true
      this.userLoaded = true
      const action = this.userLoaded || this.anonymousUserLoaded ? 'updateOptions' : 'initialize'
      initializeAnonymousAppUser({ name: this.gSettings.customer_name }, action)
    }
  },
  methods: {
    onCancel () {
      this.$router.push({ name: 'Login' })
    },
    onResend () {
      this.step = 1
    },
    onGetCode (data) {
      this.channel = data.channel
      this.value = data.value
      this.uuid = data.uuid
      this.step++
    },
    onValidateCode (accessCode) {
      this.access_code = accessCode
      this.step++
      if (this.accounts.length === 1) {
        this.selectedAccount = this.accounts[0]
      }
    },
    setAccountToActivate (user) {
      this.selectedAccount = user
      this.uuid = user.uuid
      this.$store.dispatch('SET_SHOW_SELECT_ACCOUNT', false)
    },
    onSubmitCredentials () {
      return loggedUserIsRespondent() ? this.$router.push({ name: 'Welcome' }) : this.$router.push({ name: 'WelcomeClinician' })
    }
  }
}
</script>
