<template>
  <div>
    <select-account
      v-if="showAccountSelector"
      :accounts="accounts"
      @submit="onSetUser"
      @cancel="onCancel"
    />

    <form
      v-show="!showAccountSelector"
      class="form-container set-user-password"
    >
      <p class="text-blue title">
        {{ $t("accountSetup") }}
      </p>
      <div class="form-group">
        <label for="username">{{ $t("createUsername") }}</label>
        <input
          id="username"
          v-model="username"
          v-validate="'required|min:6'"
          type="text"
          class="form-control"
          name="username"
          @change="resetResponseErrors"
        >
        <span
          v-show="errors.has('username')"
          class="error"
        >{{ errors.first('username') }}</span>
        <span class="password-explanation"> {{ $t('usernameExplanation') }} </span>
      </div>

      <div class="form-group">
        <Password
          v-model="password"
          v-validate="{ required: true, min: passwordLength, regex: passwordRegex }"
          :label="$t('createPassword')"
          name="password"
          :show-password-criteria="true"
          @change="resetResponseErrors"
        />
        <span
          v-show="errors.has('password') && hasErrorFromFromApi('password')"
          class="error"
        >{{ errors.first('password') }}</span>
      </div>

      <div
        v-if="responseErrors && responseErrors.length"
        id="errors"
      >
        <span
          v-for="(error, key) in responseErrors"
          :key="key"
          class="error"
        >
          {{ error }}
        </span>
      </div>
      <div class="button-container">
        <button
          :disabled="submitIsDisabled"
          type="submit"
          class="btn btn-primary"
          @click.prevent="onSubmit"
        >
          {{ $t("activateAccount") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { mapGetters } from 'vuex'
import SelectAccount from './SelectAccount'
import Password from '@/components/security/Password'

export default {
  name: 'SetCredentials',
  components: {
    Password, SelectAccount
  },
  mixins: [ErrorsDictionary],
  props: {
    accounts: {
      type: Array,
      default: null
    },
    uuid: {
      type: String,
      default: null
    },
    accessCode: {},
    channel: {},
    account: {},
    value: {}
  },
  data () {
    return {
      passwordLength: null,
      username: '',
      password: '',
      submitting: false,
      responseErrors: []
    }
  },
  computed: {
    ...mapGetters({
      resetToken: 'getResetToken',
      showAccountSelector: 'getShowSelectAccount',
      accessCodeUUID: 'getAccessCodeUUID'
    }),
    submitIsDisabled () {
      return (this.errors.any() || this.submitting || this.responseErrors.length > 0)
    }
  },
  methods: {
    hasErrorFromFromApi (field) {
      return this.errors.items.find(i => i.field === field && !i.rule)
    },
    resetResponseErrors () {
      this.responseErrors = []
      this.$validator.reset()
    },
    onCancel () {
      this.$emit('cancel')
    },
    onSetUser (user) {
      this.$emit('setUser', user)
    },
    onSubmit () {
      return this.validateForm().then(this.validateFormCallback)
    },
    validateForm () {
      return this.$validator.validateAll({
        username: this.username,
        password: this.password
      })
    },
    validateFormCallback (formIsValid) {
      if (!this.uuid && !this.accounts[0].uuid) {
        this.showAccountSelector = true
        return false
      }

      return formIsValid ? this.setCredentials() : false
    },
    setCredentials () {
      this.resetResponseErrors()
      this.setSubmittingState()
      this.sendRequest().then(this.requestCallback).finally(this.unsetSubmittingState)
      return true
    },
    setSubmittingState () {
      this.loader = this.$loading.show()
      this.submitting = true
    },
    sendRequest () {
      return this.$store.dispatch('ACCOUNT_SETUP', this.getRequestData())
    },
    getRequestData () {
      if (!this.account.uuid) {
        this.account = this.accounts[0]
      }
      return {
        id: this.account.id,
        uuid: this.account.uuid,
        channel: this.channel,
        value: this.value,
        username: this.username,
        password: this.password,
        token: this.resetToken
      }
    },
    requestCallback (response) {
      const hasError = response && response.status !== 200
      return hasError ? this.requestFailure(response) : this.requestSuccess()
    },
    requestSuccess () {
      this.$store.commit('security/SET_RESET_TOKEN', null)
      this.$emit('submit')
      return true
    },
    requestFailure (response) {
      const fieldErrors = ['username', 'password']
      if (response.data.errors) {
        Object.keys(response.data.errors).forEach((field) => {
          const error = response.data.errors[field]
          const isArray = Array.isArray(error)
          const errorToAdd = isArray ? error[0] : error
          if (fieldErrors.includes(field)) {
            return this.$validator.errors.add(field, errorToAdd)
          }
          this.responseErrors.push(errorToAdd)
        })
      } else {
        this.responseErrors.push(response.data.errors)
      }
    },
    unsetSubmittingState () {
      if (this.loader) { this.loader.hide() }
      this.submitting = false
    }
  }
}
</script>
